
import { Application, Context, DisplayObject, Ticker } from '@icepanel/app-canvas'
import { ModelObject } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import * as env from '@/helpers/env'
import * as ObjectPool from '@/helpers/object-pool'
import getPixiApp from '@/helpers/pixi'
import { DomainModule } from '@/modules/domain/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { NavigationModule } from '@/modules/navigation/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import { ModelModule } from '../../store'

const OBJECT_WIDTH = 260
const OBJECT_HEIGHT = 128
const OBJECT_GAP_Y = 16

interface Object {
  domainText?: string
  id: string
  model: ModelObject
  x: number
  y: number
}

interface Dependency {
  connectionCount: number
  id: string
  originHandleId: string
  originId: string
  targetHandleId: string
  targetId: string
}

@Component({
  name: 'ModelDependencyCanvas'
})
export default class ModelDependencyCanvas extends Vue {
  domainModule = getModule(DomainModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  navigationModule = getModule(NavigationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  app?: InstanceType<typeof Application<HTMLCanvasElement>> | undefined
  view?: Context.Dependency.View | undefined
  error = ''

  canvasWidth = 0
  canvasHeight = 0

  leftScrollHeight = 0
  leftScrollY = 0

  rightScrollHeight = 0
  rightScrollY = 0

  @Ref() readonly canvasContainerRef!: HTMLElement
  @Ref() readonly canvasRef!: HTMLElement
  @Ref() readonly leftScrollRef!: HTMLElement
  @Ref() readonly rightScrollRef!: HTMLElement

  @Prop() readonly focusedObject?: ModelObject
  @Prop() readonly incomingObjects!: ModelObject[]
  @Prop() readonly outgoingObjects!: ModelObject[]
  @Prop() readonly incomingConnections!: Record<string, { lower: string[], direct: string[] }>
  @Prop() readonly outgoingConnections!: Record<string, { lower: string[], direct: string[] }>

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentDomainHandleId () {
    return this.$queryValue('domain')
  }

  get currentObjectHandleIds () {
    return this.$queryArray('object')
  }

  get currentDependencyOriginHandleId () {
    return this.$queryValue('dependency_origin')
  }

  get currentDependencyTargetHandleId () {
    return this.$queryValue('dependency_target')
  }

  get currentDependencyOrigin () {
    return Object.values(this.modelModule.objects).find(o => o.handleId === this.currentDependencyOriginHandleId)
  }

  get currentDependencyTarget () {
    return Object.values(this.modelModule.objects).find(o => o.handleId === this.currentDependencyTargetHandleId)
  }

  get currentDomain () {
    return Object.values(this.domainModule.domains).find(o => o.handleId === this.currentDomainHandleId)
  }

  get sidebarExpanded () {
    return this.navigationModule.expanded
  }

  get setObjectExternal () {
    return (object: ModelObject) => {
      const parents = object?.parentIds.map(o => this.modelModule.objects[o]) || []
      const external = object.external || parents.some(o => o.external)
      return {
        ...object,
        external
      }
    }
  }

  get setObjectStatus () {
    return (object: ModelObject) => {
      const parents = object?.parentIds.map(o => this.modelModule.objects[o]) || []
      const statusLockParent = [object, ...parents].reverse().find(o => o.status === 'future' || o.status === 'deprecated' || o.status === 'removed')
      const status = statusLockParent ? statusLockParent.status : object.status
      return {
        ...object,
        status
      }
    }
  }

  get visibleIncomingDependencies () {
    return Object
      .entries(this.incomingConnections)
      .map(([key, val]): Dependency => {
        const [originId, targetId] = key.split('-')
        const origin = this.modelModule.objects[originId]
        const target = this.modelModule.objects[targetId]
        return {
          connectionCount: val.direct.length + val.lower.length,
          id: key,
          originHandleId: origin.handleId,
          originId,
          targetHandleId: target.handleId,
          targetId
        }
      })
      .filter(o => this.visibleIncomingObjects[o.originId])
      .reduce<Record<string, Dependency>>((p, c) => ({
        ...p,
        [c.id]: c
      }), {})
  }

  get visibleOutgoingDependencies () {
    return Object
      .entries(this.outgoingConnections)
      .map(([key, val]): Dependency | undefined => {
        const [originId, targetId] = key.split('-')
        const origin = this.modelModule.objects[originId]
        const target = this.modelModule.objects[targetId]
        if (origin && target) {
          return {
            connectionCount: val.direct.length + val.lower.length,
            id: key,
            originHandleId: origin.handleId,
            originId,
            targetHandleId: target.handleId,
            targetId
          }
        } else {
          return undefined
        }
      })
      .filter(o => (o && this.visibleOutgoingObjects[o.targetId]))
      .reduce<Record<string, Dependency>>((p, c) => ({
        ...p!,
        [c!.id]: c!
      }), {})
  }

  get visibleFocusedObject (): Record<string, Object> {
    if (!this.focusedObject) {
      return {}
    }

    const objectWithExternal = this.setObjectExternal(this.focusedObject)
    const objectWithStatus = this.setObjectStatus(objectWithExternal)
    let y: number
    if (!this.leftScrollHeight && !this.rightScrollHeight) {
      y = 0
    } else if (this.leftScrollHeight - OBJECT_GAP_Y >= this.canvasHeight || this.rightScrollHeight - OBJECT_GAP_Y >= this.canvasHeight) {
      y = Math.max(
        0,
        (this.canvasHeight / 2) - (OBJECT_HEIGHT / 2)
      )
    } else {
      y = Math.max(
        0,
        ((this.leftScrollHeight - OBJECT_GAP_Y) / 2) - (OBJECT_HEIGHT / 2),
        ((this.rightScrollHeight - OBJECT_GAP_Y) / 2) - (OBJECT_HEIGHT / 2)
      )
    }
    return {
      [this.focusedObject.id]: {
        domainText: this.focusedObject.domainId !== this.currentDomain?.id ? this.domainModule.domains[this.focusedObject.domainId]?.name : undefined,
        id: this.focusedObject.id,
        model: objectWithStatus,
        x: (this.canvasWidth / 2) - (OBJECT_WIDTH / 2),
        y: y + 1
      }
    }
  }

  get visibleIncomingObjects () {
    return this.incomingObjects
      .map((o, i): Object => {
        const objectWithExternal = this.setObjectExternal(o)
        const objectWithStatus = this.setObjectStatus(objectWithExternal)
        return {
          domainText: o.domainId !== this.currentDomain?.id ? this.domainModule.domains[o.domainId]?.name : undefined,
          id: o.id,
          model: objectWithStatus,
          x: 0,
          y: 1 + i * (OBJECT_HEIGHT + OBJECT_GAP_Y)
        }
      })
      .filter(o => o.y > this.leftScrollY - OBJECT_HEIGHT && o.y < this.leftScrollY + this.canvasHeight)
      .reduce<Record<string, Object>>((p, c) => ({
        ...p,
        [c.id]: c
      }), {})
  }

  get visibleOutgoingObjects () {
    return this.outgoingObjects
      .map((o, i): Object => {
        const objectWithExternal = this.setObjectExternal(o)
        const objectWithStatus = this.setObjectStatus(objectWithExternal)
        return {
          domainText: o.domainId !== this.currentDomain?.id ? this.domainModule.domains[o.domainId]?.name : undefined,
          id: o.id,
          model: objectWithStatus,
          x: 0,
          y: 1 + i * (OBJECT_HEIGHT + OBJECT_GAP_Y)
        }
      })
      .filter(o => o.y > this.rightScrollY - OBJECT_HEIGHT && o.y < this.rightScrollY + this.canvasHeight)
      .reduce<Record<string, Object>>((p, c) => ({
        ...p,
        [c.id]: c
      }), {})
  }

  get visibleObjects () {
    return {
      focused: this.visibleFocusedObject,
      incoming: this.visibleIncomingObjects,
      outgoing: this.visibleOutgoingObjects
    }
  }

  @Watch('sidebarExpanded')
  onSidebarExpandedChanged () {
    setTimeout(() => {
      this.onResize()
    }, 100)
  }

  @Watch('incomingObjects')
  async onIncomingObjectsChanged (objects: Object[]) {
    this.leftScrollHeight = objects.length * (OBJECT_HEIGHT + OBJECT_GAP_Y)

    await this.$nextTick()
    await this.removeMissingSelectedObjects()
  }

  @Watch('outgoingObjects')
  async onOutgoingObjectsChanged (objects: Object[]) {
    this.rightScrollHeight = objects.length * (OBJECT_HEIGHT + OBJECT_GAP_Y)

    await this.$nextTick()
    await this.removeMissingSelectedObjects()
  }

  @Watch('currentDomainHandleId')
  async onCurrentDomainChanged () {
    await this.$nextTick()
    await this.removeMissingSelectedObjects()
  }

  @Watch('visibleObjects')
  onVisibleObjectsChanged (objects: ModelDependencyCanvas['visibleObjects'], prevObjects: ModelDependencyCanvas['visibleObjects']) {
    const keys = ['focused', 'incoming', 'outgoing'] as const
    keys.forEach(key => {
      Object
        .keys(objects[key])
        .filter(o => prevObjects[key][o] && objects[key][o].model.type !== prevObjects[key][o].model.type)
        .forEach(o => this.removeObject(key, o))

      Object
        .keys(prevObjects[key])
        .filter(o => !objects[key][o])
        .forEach(o => this.removeObject(key, o))

      Object
        .entries(objects[key])
        .forEach(([id, o]) => this.upsertObject(key, o, prevObjects[key][id]))

      Object
        .keys(objects[key])
        .filter(o => prevObjects[key][o] && objects[key][o].model.type !== prevObjects[key][o].model.type)
        .forEach(o => this.initObject(key, o))

      Object
        .keys(objects[key])
        .filter(o => !prevObjects[key][o])
        .forEach(id => this.initObject(key, id))
    })
  }

  @Watch('visibleIncomingDependencies')
  onVisibleIncomingDepdenciesChanged (dependencies: Record<string, Dependency>, prevDepdencies: Record<string, Dependency>) {
    Object
      .keys(prevDepdencies)
      .filter(o => !dependencies[o])
      .forEach(o => this.removeDependency('incoming', o))

    Object
      .entries(dependencies)
      .forEach(([id, o]) => this.upsertDependency('incoming', id, o))

    Object
      .keys(dependencies)
      .filter(o => !prevDepdencies[o])
      .forEach(id => this.initDependency('incoming', id))

    if (Object.keys(dependencies).length !== Object.keys(prevDepdencies).length) {
      this.onResize()
    }
  }

  @Watch('visibleOutgoingDependencies')
  onVisibleOutgoingDepdenciesChanged (dependencies: Record<string, Dependency>, prevDepdencies: Record<string, Dependency>) {
    Object
      .keys(prevDepdencies)
      .filter(o => !dependencies[o])
      .forEach(o => this.removeDependency('outgoing', o))

    Object
      .entries(dependencies)
      .forEach(([id, o]) => this.upsertDependency('outgoing', id, o))

    Object
      .keys(dependencies)
      .filter(o => !prevDepdencies[o])
      .forEach(id => this.initDependency('outgoing', id))

    if (Object.keys(dependencies).length !== Object.keys(prevDepdencies).length) {
      this.onResize()
    }

    this.view?.centerObject?.setConnectorTypes({
      'right-middle': Object.keys(dependencies).length ? this.view.centerObject.selected ? 'highlight' : 'connection' : null
    })
  }

  @Watch('currentObjectHandleIds')
  onCurrentObjectHandleIdsChanged (currentObjectHandleIds: string[]) {
    if (this.view) {
      this.view.selectedObjectHandleIds = currentObjectHandleIds
    }
  }

  @Watch('currentDependencyOrigin')
  onCurrentDependencyOriginChanged (currentDependencyOrigin: ModelObject | undefined) {
    if (this.view) {
      this.view.selectedDependencyOriginId = currentDependencyOrigin?.id ?? null
    }
  }

  @Watch('currentDependencyTarget')
  onCurrentDependencyTargetChanged (currentDependencyTarget: ModelObject | undefined) {
    if (this.view) {
      this.view.selectedDependencyTargetId = currentDependencyTarget?.id ?? null
    }
  }

  @Watch('leftScrollY')
  onLeftScrollYChanged () {
    this.layoutLeftConnections()
  }

  @Watch('leftScrollHeight')
  onLeftScrollHeightChanged () {
    this.layoutLeftConnections()
  }

  @Watch('rightScrollY')
  onRightScrollYChanged () {
    this.layoutRightConnections()
  }

  @Watch('rightScrollHeight')
  onRightScrollHeightChanged () {
    this.layoutRightConnections()
  }

  mounted () {
    try {
      this.app = getPixiApp()
      this.app.resizeTo = this.canvasContainerRef

      this.view = new Context.Dependency.View(this.app, {
        iconUrl: env.ICON_URL
      })

      this.view.on('select', async ({ objectIds, dependencyOrigin, dependencyTarget }) => {
        const query: any = {}
        if (objectIds) {
          query.object = objectIds
        }
        query.dependency_origin = dependencyOrigin
        query.dependency_target = dependencyTarget

        if (objectIds.length) {
          this.navigationModule.setSidebarExpanded(false)
        }

        await this.$pushQuery(query)
      })

      this.canvasRef.onselectstart = () => false

      // tabbing will create a div that is more than the height of the page
      this.app.renderer.plugins.accessibility.destroy()

      if (window.Cypress) {
        window.view = this.view
      }

      Ticker.shared.start()

      this.canvasRef.appendChild(this.app.view)
      this.onResize()
      this.app.view.focus({ preventScroll: true })

      Object.values(this.visibleFocusedObject).forEach(o => this.upsertObject('focused', o))
      Object.values(this.visibleIncomingObjects).forEach(o => this.upsertObject('incoming', o))
      Object.values(this.visibleOutgoingObjects).forEach(o => this.upsertObject('outgoing', o))

      Object.values(this.visibleFocusedObject).forEach(o => this.initObject('focused', o.id))
      Object.values(this.visibleIncomingObjects).forEach(o => this.initObject('incoming', o.id))
      Object.values(this.visibleOutgoingObjects).forEach(o => this.initObject('outgoing', o.id))

      this.view.selectedObjectHandleIds = this.currentObjectHandleIds
      this.view.selectedDependencyOriginId = this.currentDependencyOrigin?.id ?? null
      this.view.selectedDependencyTargetId = this.currentDependencyTarget?.id ?? null

      this.leftScrollHeight = this.incomingObjects.length * (OBJECT_HEIGHT + OBJECT_GAP_Y)
      this.rightScrollHeight = this.outgoingObjects.length * (OBJECT_HEIGHT + OBJECT_GAP_Y)

      Object.values(this.visibleIncomingDependencies).forEach(o => this.upsertDependency('incoming', o.id, o))
      Object.values(this.visibleOutgoingDependencies).forEach(o => this.upsertDependency('outgoing', o.id, o))

      Object.values(this.visibleIncomingDependencies).forEach(o => this.initDependency('incoming', o.id))
      Object.values(this.visibleOutgoingDependencies).forEach(o => this.initDependency('outgoing', o.id))

      this.$emit('loaded')
    } catch (err: any) {
      this.$emit('error', err.message)
      throw err
    }
  }

  beforeDestroy () {
    if (this.app) {
      this.canvasRef.removeChild(this.app.view)
    }
  }

  destroyed () {
    Ticker.shared.stop()

    if (this.view) {
      if (this.view.centerObject) {
        this.removeObject('focused', this.view.centerObject.id)
      }

      Object.keys(this.view.leftObjects || {}).forEach(o => this.removeObject('incoming', o))
      Object.keys(this.view.rightObjects || {}).forEach(o => this.removeObject('outgoing', o))

      this.view.destroy()
    }
  }

  async onResize () {
    if (this.app && this.canvasRef.childElementCount) {
      this.canvasWidth = this.canvasContainerRef.clientWidth
      this.canvasHeight = this.canvasContainerRef.clientHeight
    }

    if (this.view) {
      this.view.leftObjectContainer.x = 1
      this.view.leftObjectContainer.y = -this.leftScrollY
      this.view.rightObjectContainer.x = this.canvasWidth - OBJECT_WIDTH - 1
      this.view.rightObjectContainer.y = -this.rightScrollY
    }

    if (this.view) {
      Object.values(this.visibleFocusedObject).forEach(o => this.upsertObject('focused', o))
      Object.values(this.visibleFocusedObject).forEach(o => this.initObject('focused', o.id))
    }

    this.layoutLeftConnections()
    this.layoutRightConnections()

    this.app?.renderer.resize(this.canvasWidth, this.canvasHeight)
    this.app?.render()
  }

  onWheel (event: WheelEvent) {
    const scrollWidth = (this.canvasWidth - OBJECT_WIDTH - OBJECT_WIDTH) / 2
    if (event.offsetX < scrollWidth) {
      this.leftScrollRef.scrollTop += event.deltaY
    } else if (event.offsetX > this.canvasWidth - scrollWidth) {
      this.rightScrollRef.scrollTop += event.deltaY
    }
  }

  leftScrollChanged (event: Event) {
    this.leftScrollY = (event.target as any)?.scrollTop

    if (this.view) {
      this.view.leftObjectContainer.y = -this.leftScrollY
    }
  }

  rightScrollChanged (event: Event) {
    this.rightScrollY = (event.target as any)?.scrollTop

    if (this.view) {
      this.view.rightObjectContainer.y = -this.rightScrollY
    }
  }

  upsertObject (type: 'focused' | 'incoming' | 'outgoing', object: Object, prevObject?: Object) {
    if (this.view) {
      let obj: Context.Dependency.ViewObject | undefined
      if (type === 'incoming') {
        obj = this.view.leftObjects[object.id]
      } else if (type === 'outgoing') {
        obj = this.view.rightObjects[object.id]
      } else if (type === 'focused') {
        obj = this.view.centerObject ?? undefined
      }
      const connectorEnabled = type === 'incoming' || (type === 'focused' && !!Object.keys(this.visibleOutgoingObjects).length)
      if (obj) {
        const ignoreVersion = object.model.external !== prevObject?.model.external || object.model.status !== prevObject?.model.status
        obj.setStore({
          connectorEnabled,
          domainText: object.domainText
        })
        obj.setModel(object.model, ignoreVersion)
        obj.x = object.x
        obj.y = object.y
        return obj
      } else {
        switch (object.model.type) {
          case 'system': {
            obj = ObjectPool.reuseObject(Context.Dependency.Obj.System, object.id) ?? new Context.Dependency.Obj.System(this.view, object.id)
            break
          }
          case 'actor': {
            obj = ObjectPool.reuseObject(Context.Dependency.Obj.Actor, object.id) ?? new Context.Dependency.Obj.Actor(this.view, object.id)
            break
          }
          case 'group': {
            obj = ObjectPool.reuseObject(Context.Dependency.Obj.Group, object.id) ?? new Context.Dependency.Obj.Group(this.view, object.id)
            break
          }
          case 'app': {
            obj = ObjectPool.reuseObject(Context.Dependency.Obj.App, object.id) ?? new Context.Dependency.Obj.App(this.view, object.id)
            break
          }
          case 'store': {
            obj = ObjectPool.reuseObject(Context.Dependency.Obj.Store, object.id) ?? new Context.Dependency.Obj.Store(this.view, object.id)
            break
          }
          case 'component': {
            obj = ObjectPool.reuseObject(Context.Dependency.Obj.Comp, object.id) ?? new Context.Dependency.Obj.Comp(this.view, object.id)
            break
          }
          default: {
            throw new Error(`Failed to create ${object.model.type} object`)
          }
        }
        obj.rectSize.set(OBJECT_WIDTH, OBJECT_HEIGHT)
        obj.x = object.x
        obj.y = object.y
        obj.setView(this.view)
        obj.setStore({
          connectorEnabled,
          domainText: object.domainText
        })
        obj.setModel(object.model, true)
        if (type === 'incoming') {
          this.view.leftObjects[object.id] = obj
        } else if (type === 'outgoing') {
          this.view.rightObjects[object.id] = obj
        } else if (type === 'focused') {
          this.view.centerObject = obj
        }
        ObjectPool.addObject(obj)
      }
      return obj
    } else {
      throw new Error('Could not find view')
    }
  }

  initObject (type: 'focused' | 'incoming' | 'outgoing', objectId: string) {
    if (this.view) {
      let obj: Context.Dependency.ViewObject | null = null
      if (type === 'incoming') {
        obj = this.view.leftObjects[objectId]
      } else if (type === 'outgoing') {
        obj = this.view.rightObjects[objectId]
      } else if (type === 'focused') {
        obj = this.view.centerObject
      }
      if (obj) {
        if (obj.initialized) {
          obj.resetModel()
        } else {
          obj.init()
        }
        if (type === 'incoming') {
          this.view.leftObjectContainer.addChild(obj as DisplayObject)
        } else if (type === 'outgoing') {
          this.view.rightObjectContainer.addChild(obj as DisplayObject)
        } else if (type === 'focused') {
          this.view.scene.addChild(obj as DisplayObject)
        }
        return obj
      } else {
        throw new Error('Could not find object')
      }
    } else {
      throw new Error('Could not find view')
    }
  }

  removeObject (type: 'focused' | 'incoming' | 'outgoing', objectId: string) {
    if (this.view) {
      let obj: Context.Dependency.ViewObject | null
      if (type === 'incoming') {
        obj = this.view.leftObjects[objectId]
      } else if (type === 'outgoing') {
        obj = this.view.rightObjects[objectId]
      } else {
        obj = this.view.centerObject
      }
      if (obj) {
        obj.parent.removeChild(obj as DisplayObject)
        if (type === 'incoming') {
          delete this.view.leftObjects[objectId]
        } else if (type === 'outgoing') {
          delete this.view.rightObjects[objectId]
        } else {
          this.view.centerObject = null
        }
        ObjectPool.recycleObject(obj)
      }
    }
  }

  upsertDependency (type: 'incoming' | 'outgoing', id: string, dependency: Dependency) {
    if (this.view) {
      const connections = type === 'incoming' ? this.view.leftConnections : this.view.rightConnections
      let con = connections[id]
      if (con) {
        con.setStore({
          count: dependency.connectionCount,
          originHandleId: dependency.originHandleId,
          originId: dependency.originId,
          targetHandleId: dependency.targetHandleId,
          targetId: dependency.targetId,
          type
        })
        con.updateText()
        return con
      } else {
        con = ObjectPool.reuseObject(Context.Dependency.Obj.Connection, id) ?? new Context.Dependency.Obj.Connection(this.view, id)
        con.eventMode = 'static'
        con.setView(this.view)
        con.setStore({
          count: dependency.connectionCount,
          originHandleId: dependency.originHandleId,
          originId: dependency.originId,
          targetHandleId: dependency.targetHandleId,
          targetId: dependency.targetId,
          type
        })
        connections[id] = con
        con.updateText()
        ObjectPool.addObject(con)
      }
      return con
    } else {
      throw new Error('Could not find view')
    }
  }

  initDependency (type: 'incoming' | 'outgoing', id: string) {
    if (this.view) {
      const connections = type === 'incoming' ? this.view.leftConnections : this.view.rightConnections
      const con = connections[id]
      if (!con.initialized) {
        con.init()
      }

      this.view.scene.addChild(con as DisplayObject)

      return con
    } else {
      throw new Error('Could not find view')
    }
  }

  removeDependency (type: 'incoming' | 'outgoing', id: string) {
    if (this.view) {
      const connections = type === 'incoming' ? this.view.leftConnections : this.view.rightConnections
      const con = connections[id]
      if (con) {
        con.parent.removeChild(con as DisplayObject)
        delete connections[id]
        ObjectPool.recycleObject(con)
      }
    }
  }

  layoutLeftConnections () {
    if (this.view) {
      Object.values(this.view.leftConnections).forEach(o => {
        o.updateLinePoints()
        o.updateText()
      })
    }
  }

  layoutRightConnections () {
    if (this.view) {
      Object.values(this.view.rightConnections).forEach(o => {
        o.updateLinePoints()
        o.updateText()
      })
    }
  }

  async removeMissingSelectedObjects () {
    const objectHandleIds = [...this.incomingObjects, ...this.outgoingObjects, ...this.focusedObject ? [this.focusedObject] : []].map(o => o.handleId)
    const selectedObjectHandleIdsMissing = this.currentObjectHandleIds.filter(o => !objectHandleIds.includes(o))
    if (!selectedObjectHandleIdsMissing.length) { return }

    await this.$replaceQuery({
      object: this.$removeQueryArray(...selectedObjectHandleIdsMissing)
    })
  }
}
