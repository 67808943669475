
import { Diagram, ModelConnection, ModelObject } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { iconUrlForTheme } from '@/helpers/theme'
import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'

interface IModelConnection {
  diagrams: Diagram[]
  id: string
  modelConnection: ModelConnection
  receiver: ModelObject
  receiverIconUrl?: string
  sender: ModelObject
  senderIconUrl?: string
}

@Component({
  components: {
    Dialog
  },
  name: 'ModelConnectionDeleteDialog'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly dialog!: Dialog

  @Ref() connectionScrollRef!: HTMLElement

  loading = false

  connectionCache: IModelConnection[] | null = null

  connectionScrollYVisible = false

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape.organizationId
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentDiagarmHandleId () {
    return this.$queryValue('diagram')
  }

  get connectionIds () {
    return this.$queryValue('connection_delete_dialog')?.split(',') || []
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagarmHandleId)
  }

  get currentDiagramContent () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagarmHandleId)
  }

  get currentDiagramDraft () {
    return this.currentDiagram?.status === 'draft'
  }

  get connections () {
    if (this.connectionCache) {
      return this.connectionCache
    } else {
      return this.connectionIds.map((o): IModelConnection => {
        const modelConnection = this.modelModule.connections[o]

        const diagrams = Object
          .keys(modelConnection.diagrams)
          .map(o => this.diagramModule.diagrams[o])
          .sort((a, b) => a.name.localeCompare(b.name))

        const sender = this.modelModule.objects[modelConnection.originId]
        const senderIconUrl = sender.icon ? iconUrlForTheme(sender.icon) : undefined

        const receiver = this.modelModule.objects[modelConnection.targetId]
        const receiverIconUrl = receiver.icon ? iconUrlForTheme(receiver.icon) : undefined

        return {
          diagrams,
          id: o,
          modelConnection,
          receiver,
          receiverIconUrl,
          sender,
          senderIconUrl
        }
      }) || []
    }
  }

  @Watch('connections')
  async onObjectsChanged () {
    await this.$nextTick()
    this.connectionScrollYVisible = this.connectionScrollRef ? this.connectionScrollRef.scrollHeight > this.connectionScrollRef.clientHeight : false
  }

  opened () {
    analytics.modelConnectionDeleteDialog.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  close () {
    this.connectionCache = this.connections
  }

  closed () {
    this.connectionCache = null
  }

  async deleteConnection () {
    try {
      this.loading = true

      this.connectionCache = this.connections

      await this.$replaceQuery({
        connection: undefined,
        flow: undefined,
        flow_parent: undefined,
        flow_path: undefined,
        flow_step: undefined,
        object: undefined
      })

      for (const connectionId of this.connectionIds) {
        if (this.currentDiagram?.status === 'draft' && this.currentDiagramContent) {
          const connection = Object.values(this.currentDiagramContent.connections).find(o => o.modelId === connectionId)
          const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
            connections: {
              $remove: connection ? [connection.id] : undefined
            },
            tasksProposed: {
              $append: [{
                id: connectionId,
                type: 'model-connection-delete'
              }]
            }
          })
          await this.diagramModule.diagramContentUpdate({
            diagramId: diagramContent.id,
            landscapeId: this.currentLandscape.id,
            props: diagramContentUpdate,
            versionId: this.currentDiagram.versionId
          })
        } else {
          await this.modelModule.connectionDelete({
            connectionId,
            landscapeId: this.currentLandscape.id,
            versionId: this.currentVersion.id
          })
        }
      }

      this.editorModule.resetTaskLists()

      if (this.currentDiagram) {
        await this.diagramModule.diagramContentFind({
          diagramId: this.currentDiagram.id,
          landscapeId: this.currentLandscape.id,
          versionId: this.currentVersion.id
        })
      }

      this.$emit('delete', this.connectionIds)

      await this.$replaceQuery({
        connection_delete_dialog: undefined
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.message
      })
    } finally {
      this.loading = false
    }
  }
}
